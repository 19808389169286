@debug: true;

@font-family-base: 'Open Sans', 'Helvetica', 'Arial', sans-serif;

@red-mamasfud: #af2222;

@brand-primary: #af2222;

@red-text: #af2222;

@dark-gray: #acacac;
@light-gray: #f7f7f7;

@mid-gray: #f7f7f7;
@dark-text: #acacac;
@mud: #acacac;

/* Bootstrap modal overlay bg */
@modal-backdrop-bg: white;

@navbar-height: 32px;
