

/*Begin CSS styling*/

html, body {
  height: 100%;
  font-size: 13px;
  color: black;
}

body {
  font-family: @font-family-base;

  input, label{
    line-height: 20px !important;
  }

  label {
    margin-top: 4px;
  }

  h1, h2, h3, h4{
    margin-top: 10px;
  }

  input[type=text],
  input[type=email],
  input[type=password]{
    height: 24px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  /*Swiper.js*/
  .swiper-button-next, .swiper-button-prev{
    background-image: none;
  }

  .swiper-pagination-bullet-active{
    background-color: black;
  }

  .swiper-container-horizontal {
    .swiper-pagination{
      bottom: -5px;
    }
  }

  .mCS-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-color: black;
  }

  .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    width: 8px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: black;
    opacity: 1;
  }

  .mCSB_scrollTools .mCSB_dragger{
    height: 25px;
    min-height: 25px;
  }

  .mCSB_scrollTools .mCSB_draggerRail{
    width: 1px;
  }

  .mCSB_scrollTools{
    opacity: 1;
  }
}

button{
  padding: 0;
  border: none;
  background: none;
}

ul, li {
  list-style: none;
}

video{
  display: block;
}

a:not([href]) {
  text-decoration: none;
}

textarea, input, a { outline: none; }
:focus {
  outline-color: transparent;
  outline-style: none;
  outline: none !important;
  box-shadow:none;
  border: none;
}

textarea, textarea:focus {
  outline-color: @dark-gray;
  outline-style: solid;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid @dark-gray;
}

.close{
  opacity: 1;
}

/* Bootstrap modal override*/

body.modal-open::-webkit-scrollbar {
  display: none;
}

.modal-content{
  border-radius: 0;
  border: 0;
}

.modal-header{
  border: 0;

  .fa-times{
    padding: 10px;
  }
}

.modal-footer{
  border: 0;
}

.modal-body{
  min-height: 300px;
}
/* Bootstrap modal override END*/


/* Feedback messages */
.error-message{
  color: @red-text;
  font-weight: bold;
}

.success-message{
  font-weight: bold;
  color: @red-text;
}

/* Feedback messages END */

/* Alert */
.alert{
  border-radius: 0px;
  border: solid 2px;
  background-color: transparent;
  margin-bottom: 35px;
}

.alert-danger{
  border-color: @red-text;
  color: @red-text
}
/* Alert END */

/* Menu animations */
/*
.dropdown.open  .dropdown-menu{
  display: block;
  max-height: 1000px;
  -moz-transition:    all 1000ms ease-out;
  -webkit-transition: all 1000ms ease-out;
  -o-transition:      all 1000ms ease-out;
  -ms-transition:     all 1000ms ease-out;
  transition:         all 1000ms ease-out;
  overflow: hidden;
  border: none;
}
*/

.dropdown .dropdown-menu {
  padding: 0 0 25px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
}

/* Menu animations END */

/* Angular animations */
.animate-show {
  max-height: 600px;
  transition: max-height 0.5s ease-in;
  overflow: hidden;

}

.animate-show.ng-hide-add, .animate-show.ng-hide-remove {
}

.animate-show.ng-hide {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

/* Angular animations */


/* jQuery Validate */
label.error{
  width: 100%;
  text-align: right;
  float: left;
  color: @red-text;
  margin-top: 0;
}
/* jQuery Validate END */

/* Sella GestPay */

.Off{
  display: none;
}

.On{
  display: inherit;
}

/* Sella GestPay END */

/* Typeahead.js */
.tt-query,
.tt-hint {
  width: 190px;
  height: 30px;
  padding: 8px 12px;
  line-height: 30px;
  border: 2px solid #ccc;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 190px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: @red-text;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: @red-text;

}

.tt-suggestion p {
  margin: 0;
}
/* Typeahead.js END*/

.mm-slideout{
  min-height: 100%;
  /*padding-bottom: 491px;*/
}

.padded-box{
  z-index: 1000;
}

.btn, .btn:hover{
  background: @dark-gray!important;
  width: 100%;
  color: #fff!important;
  line-height: 18px!important;
  font-size: 18px!important;
  border-radius: 0!important;
  font-weight: 500;
  padding: 6px 12px!important;
  height: 32px!important;
}

.btn--invert, .btn--invert:hover, .btn--invert:focus{
  background: white!important;
  color: @dark-gray!important;
  border: 1px solid @dark-gray!important;
  width: 100%;
  line-height: 18px!important;
  font-size: 18px!important;
  border-radius: 0!important;
  font-weight: 500;
  padding: 6px 12px!important;
  height: 32px!important;

}

.badge{
  position: absolute;
  padding: 0!important;
  background: #e4e5e4;
  height: 16px;
  width: 16px;
  right: -4px;
  top: 0px;
  text-align: center;
  line-height: 16px;
  font-size: 10px!important;
  border-radius: 50%;
  color: black;
  border: 1px solid @dark-gray;
}

/*
input[type=radio],
input[type=checkbox] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input[type=radio] ~ label:not(.error):before{
  font-family: FontAwesome;
  display: inline-block;
  content: "\f10c";
  letter-spacing: 10px;
  font-size: 1.2em;
  color: #535353;
  width: 1.4em;
}

input[type=checkbox] ~ label:not(.error):before {
  font-family: FontAwesome;
  display: inline-block;
  content: "\f096";
  letter-spacing: 10px;
  font-size: 1.2em;
  color: #535353;
  width: 1.4em;
}

input[type=radio]:checked ~ label:not(.error):before{
  content: "\f192";
  font-size: 1.2em;
  letter-spacing: 5px;
}

input[type=checkbox]:checked ~ label:not(.error):before  {
  content: "\f00c";
  font-size: 1.2em;
  letter-spacing: 5px;
}
input[type=checkbox] ~ label:not(.error):before {
  content: "\f096";
}
input[type=checkbox]:checked ~ label:not(.error):before {
  content: "\f046";
}
*/
/**
 *
 * .layout
 *
**/

.layout__header{

  background-color: @red-mamasfud;

  ul{
    padding: 0;
  }

  .layout__header-bar{
    padding: 10px 0;

    .layout__header-bar-menu{

      color: @dark-text;
      li{
        float: left;
        line-height: 48px;
        span{
          font-weight: 500;
        }
        .fa{
          border-left: 1px solid @mid-gray;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 18px!important;

        }
      }

      li:first-child{
        .fa{
          padding-left: 0;
        }
      }

      li:first-child{
        .fa{
          border-left: none;
        }

      }

      .layout__header-bar-logo {
          max-height: 50px;
      }

    }

    .layout__header-bar-shopmenu{

        margin: 0 -10px;


      a, a:link, a:hover, a:focus, a:active{
        text-decoration: none;
        font-size: 16px;
        color: white;
        //line-height: 50px;

        /*span{
          text-decoration: underline;
        }*/

        .fa {
            font-size: 24px!important;
            padding-right: 5px;
        }

      }


      li{
        float: right;
        padding-right: 40px;
      }

      li:first-child{
        padding-right: 0;
        .fa{
          padding-right: 0;
        }
      }
    }
  }

  /*.layout__header-bar-menu {
      margin: 0 -10px;
  }
  .layout__header-bar-shopmenu{
  }
  .layout__header-bar-logo {
      //margin-top: 10px;
  }*/

  /*.layout__header-menu{
    background-color: @dark-gray;
    color: white;

    .nav{

      padding: 0;

      .fa-chevron-right.active{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      .fa-chevron-right{
        margin-left: 5px;
        font-size: 16px;
        -webkit-transition: 0.2s ease-out;
        -moz-transition: 0.2s ease-out;
        -o-transition: 0.2s ease-out;
        transition: 0.2s ease-out;
      }
    }
  }*/

}

@media (min-width: @screen-sm-min) {
    .layout__header .layout__header-bar-shopmenu a .fa {
        line-height: 50px;
    }
}

.layout__container {
    min-height: 400px;
}
#pages-index-1 .layout__container {
    min-height: 0;
}

.layout{

  .layout__search-wrapper {
    text-align: center;
    padding: 15px 0;
  }

  .layout__search {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #222;
    height: 100%;
    min-height: 23px;
    font-size: 1.5em;
    text-align: center;
    min-width: 280px;
  }

  .layout__search::-webkit-input-placeholder { color: #000; } /* Webkit */
  .layout__search::-moz-placeholder { color: #000; } /* firefox 19+ */
  .layout__search:-ms-input-placeholder { color: #000; } /* IE */
  .layout__search:-ms-input-placeholder { color: #000; } /* for Edge */

  .layout__search:focus {
    outline: none;
  }

}

/**
 *
 * .layout END
 *
**/


.list-spacer:before{
  content: "\00a0";
}

/**
 *
 * .accounts-register
 *
**/

.accounts-register {

  margin-bottom: 30px;

  .img-responsive {
    margin: auto;
    max-height: 180px;
  }

  .accounts-register__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-register__title {
      font-size: 30px;
    }
  }

  .accounts-register__title--p{
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
    color: @dark-text;
  }


  .accounts-register__wrapper {
    margin-top: 30px;

    .accounts-register__form{
      input[type=text],
      input[type=email],
      input[type=password], {
        border: 1px solid black;
        padding: 0 10px;
        width: 100%;
      }
    }

    .accounts-register__terms-text{
      font-size: 11px;
    }
  }
}

/**
 *
 * .accounts-register END
 *
**/

/**
 *
 * .accounts-login
 *
**/

.accounts-login {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  .accounts-login__header {
    margin: 0 15px;
    padding: 20px 0;
    border-bottom: 1px solid @light-gray;

    .accounts-login__title {
      font-size: 30px;
    }
  }

  .accounts-login__title{
    font-size: 18px;
  }


  .accounts-login__wrapper{
    margin-top: 30px;

    input{
      width: 100%;
      border: 1px solid black;
      padding: 0 10px;
    }

    input[type="checkbox"] {
        width: auto;
    }

    .accounts-login__register-wrapper{
      margin-top: 30px;
    }
  }
}

/**
 *
 * .accounts-login END
 *
**/

/**
 *
 * .accounts-logout
 *
**/

.accounts-logout {

  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }

  .accounts-logout__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-logout__title {
      font-size: 30px;
    }
  }

  .accounts-logout__title {
    font-size: 18px;
  }

  .accounts-logout__wrapper {
    margin-top: 30px;
  }
}

/**
 *
 * .accounts-logout END
 *
**/

/**
 *
 * .accounts-edit
 *
**/

.accounts-edit {

  p {
    margin-bottom: 0;
  }

  .accounts-edit__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-edit__title {
      font-size: 30px;
    }
  }

  .accounts-edit__title {
    font-size: 18px;
  }

  .accounts-edit__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .accounts-edit__list{
    padding: 0;
  }

  .accounts-edit__wrapper {

    .accounts-edit__form{
      input[type=text],
      input[type=email],
      input[type=password], {
        border: 1px solid black;
        padding: 0 10px;
        width: 100%;
      }
      input[disabled]{
        color: gray;
      }
    }
  }
}

/**
 *
 * .addresses-index END
 *
**/


/**
 *
 * .accounts-passwordforgotten
 *
**/

.accounts-passwordforgotten {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  input{
    width: 100%;
    border: 1px solid black;
    padding: 0 10px;
  }

  .accounts-passwordforgotten__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-passwordforgotten__title {
      font-size: 22px;
    }
  }

  .accounts-passwordforgotten__title {
    font-size: 18px;
  }

  .accounts-passwordforgotten__wrapper {
    margin-top: 30px;
  }
}
/**
 *
 * .accounts-passwordforgotten END
 *
**/

/**
 *
 * .accounts-passwordreset
 *
**/

.accounts-passwordreset {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  label.error {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  input{
    width: 100%;
    border: 1px solid black;
    padding: 0 10px;
  }

  .accounts-passwordreset__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-passwordreset__title {
      font-size: 22px;
    }
  }

  .accounts-passwordreset__title {
    font-size: 18px;
  }

  .accounts-passwordreset__wrapper {
    margin-top: 30px;
  }
}


/**
 *
 * .accounts-passwordreset END
 *
**/

/**
 *
 * .accounts-internationalization
 *
**/

.accounts-internationalization {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  label.error {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  input{
    width: 100%;
    border: 1px solid black;
    padding: 0 10px;
  }

  .accounts-internationalization__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-internationalization__title {
      font-size: 30px;
    }
  }

  .accounts-internationalization__title {
    font-size: 18px;
  }

  .accounts-internationalization__wrapper {
    margin-top: 30px;
  }
}


/**
 *
 * .accounts-internationalization END
 *
**/

/**
 *
 * .accounts-dashboard
 *
**/


.accounts-dashboard {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  .accounts-dashboard__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .accounts-dashboard__title {
      font-size: 30px;
    }
  }

  .accounts-dashboard__title{
    font-size: 18px;
  }


  .accounts-dashboard__menu-wrapper{

    ul{
      li{
        margin-bottom: 10px;
        span{
          margin-left: 5px;
        }
      }
    }
  }

  .accounts-dashboard__wrapper{

    .accounts-dashboard__menu-tile{
      padding: 30px 0;

      .fa{
        font-size: 32px;
      }
    }

    .accounts-dashboard__menu-tile:hover{
      background-color: @light-gray;
    }

    .accounts-dashboard__edit-btn{
      margin-left: 5px;
      font-size: 14px;
    }
  }

  .accounts-dashboard__postcard{
    padding: 10px;
    border: 1px solid @light-gray;
  }

}


/**
 *
 * .accounts-dashboard END
 *
**/

/**
 *
 * .addresses-index
 *
**/

.addresses-index {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  .addresses-index__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .addresses-index__title {
      font-size: 30px;
    }
  }

  .addresses-index__title {
    font-size: 18px;
  }

  .addresses-index__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .addresses-index__postcard{
    padding: 10px;
    border: 1px solid @light-gray;
  }

  .addresses-index__list{
    padding: 0;
  }
}

/**
 *
 * .addresses-index END
 *
**/

/**
 *
 * .addresses-add
 *
**/

.addresses-add {

  p {
    margin-bottom: 0;
  }

  .addresses-add__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .addresses-add__title {
      font-size: 30px;
    }
  }

  .addresses-add__title {
    font-size: 18px;
  }

  .addresses-add__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .addresses-add__list{
    padding: 0;
  }

  .addresses-add__wrapper {

    .addresses-add__form{
      input[type=text],
      input[type=email],
      input[type=password], {
        border: 1px solid black;
        padding: 0 10px;
        width: 100%;
      }
    }
  }
}

/**
 *
 * .addresses-index END
 *
**/

/**
 *
 * .addresses-edit
 *
**/

.addresses-edit {

  p {
    margin-bottom: 0;
  }

  .addresses-edit__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .addresses-edit__title {
      font-size: 30px;
    }
  }

  .addresses-edit__title {
    font-size: 18px;
  }

  .addresses-edit__menu-wrapper {

    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .addresses-edit__list{
    padding: 0;
  }

  .addresses-edit__wrapper {

    .addresses-edit__form{
      input[type=text],
      input[type=email],
      input[type=password], {
        border: 1px solid black;
        padding: 0 10px;
        width: 100%;
      }
    }
  }
}

/**
 *
 * .addresses-index END
 *
**/





/**
 *
 * .brands
 *
**/

.brands-index {

  p {
    margin-bottom: 0;
  }

  .brands-index__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .brands-index__title {
      font-size: 30px;
    }
  }

  .brands-index__title {
    font-size: 18px;
  }

  .brands-index__letter-header{
    line-height: 40px;
    color: white;
    background-color: @dark-gray;
    font-size: 18px;
    font-weight: 700;
  }

  .brands-index__brand{
    line-height: 60px;
    font-size: 18px;
  }

}


/**
 *
 * .brands END
 *
**/

/**
 *
 * .carts
 *
**/

.carts-wrongpayment {

  p {
    margin-bottom: 0;
  }

  .carts-wrongpayment__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .carts-wrongpayment__title {
      font-size: 30px;
    }
  }

  .carts-wrongpayment__title {
    font-size: 18px;
  }
}


.elements-cart {

  margin-bottom: 30px;

  .img-responsive {
    margin: auto;
    max-height: 180px;
  }

  p {
    margin: 0;
  }

  .elements-cart__header {
    margin: 0 15px;
    padding: 20px 0;
    border-bottom: 1px solid @light-gray;

    .elements-cart__title {
      font-size: 30px;
    }
  }

  .elements-cart__qty-modifier{
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
  }

  .elements-cart__payments{

    ul{
      padding: 0;
      li{
        float: left;
        width: 33%;
      }
    }

    .img-responsive{
      max-height: 25px;
      margin: auto;
    }
    span{
      font-size: 10px;
      display: inline-block;
      line-height: 12px;
      margin-top: 5px;
    }
  }

  .elements-cart__cart-row--empty{
    line-height: 150px;
    font-size: 18px;
  }

  .elements-cart__row-qty, .elements-cart__row-price{
    font-size: 16px;
  }

  .elements-cart__footer-spacer{
    border-top: 1px solid @light-gray;
  }

  .elements-cart__footer-actions{

    font-size: 16px;

    .elements-cart__promo-delete{
      font-size: 11px;
    }

    .elements-cart__promo-code{
      height: 22px;
      border: 1px solid black;
      margin-right: 15px;
      padding-right: 25px;
      text-align: right;
    }


    .elements-cart__promo-wrapper{

      .fa{
        position: absolute;
        right: 20px;
        top: 2px;
        font-size: 19px;
        transition: all 0.5s ease-out;
      }

      .elements-cart__promo-code:focus + .fa-check-circle-o,
      .elements-cart__promo-code:hover + .fa-check-circle-o,
      .fa-check-circle-o:hover{
        opacity: 1;
      }

      .fa-check-circle-o{
        opacity: 0;
      }

    }


    .elements-cart__checkout-btn{

    }
  }

  .elements-cart__terms{
    font-size: 11px;
  }

  .btn, .stripe-button-el {
    width: auto;
    background: @red-mamasfud!important;
    background-image: none;
    display: block;
    width: 100%;
    padding: 5px;
    background-color: #ba2529;
    color: #fff;
    text-transform: uppercase;
    border-radius:0;
    font-family: 'Open Sans',Helvetica,Arial,sans-serif!important;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px!important;
    height: 32px!important;
    .waves-button-input {
        font-style: normal;
        font-size: 12px;
        padding: 0;
    }
    span {
        background: @red-mamasfud!important;
        border: 0;
        border-radius:0;
        box-shadow: none;
        font-family: 'Open Sans',Helvetica,Arial,sans-serif!important;
        line-height: 20px!important;
    }
    input {
        text-transform: uppercase!important;
        font-weight: bold!important;
    }
  }

}

.elements-addressesblock__address-container{
  margin-bottom: 1em;
}

.elements-addressesblock__address-postcard{
  border: 1px solid @light-gray;
}

/**
 *
 * .documents
 *
**/

.documents-index {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  .documents-index__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .documents-index__title {
      font-size: 30px;
    }
  }

  .documents-index__title {
    font-size: 18px;
  }

  .documents-index__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .documents-index__item:nth-child(even){
    background-color: @light-gray;
  }
}


.documents-returns {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  .documents-returns__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .documents-returns__title {
      font-size: 30px;
    }
  }

  .documents-returns__title {
    font-size: 18px;
  }

  .documents-returns__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

}


.documents-item {

  margin-bottom: 30px;

  .img-responsive {
    margin: auto;
    max-height: 180px;
  }

  p {
    margin: 0;
  }

  .documents-item__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .documents-item__title {
      font-size: 30px;
    }
  }

  .documents-item__qty-modifier{
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
  }


  .documents-item__row-qty, .documents-item__row-price{
    font-size: 16px;
  }

  .documents-item__footer-spacer{
    border-top: 1px solid @light-gray;
  }

  .documents-item__footer-actions{

    font-size: 16px;

    .documents-item__promo-delete{
      font-size: 11px;
    }
  }
}


/**
 *
 * .documents END
 *
**/

/**
 *
 * .carts END
 *
**/

/**
 *
 * .customercare
 *
**/
.customercare-pages {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  .customercare-pages__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .customercare-pages__title {
      font-size: 30px;
    }
  }

  .customercare-pages__placeholder{
    h2{
      margin-top: 0;
    }
    font-size: 18px;
  }


  .customercare-pages__menu-wrapper{

    ul{
      li{
        margin-bottom: 10px;
      }
    }
  }

  .customercare-pages__wrapper{

    .customercare-pages__section{
      display: none;
    }
  }
}

.customercare-boutiques {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  ul{
    padding: 0;
  }

  .customercare-boutiques__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .customercare-boutiques__title {
      font-size: 30px;
    }
  }

  .img-responsive{
    margin: auto;
  }

  .fa-chevron-left, .fa-chevron-right{
    color: @light-gray;
  }
  .swiper-container{
    font-size: 34px;

    .swiper-button-disabled{
      opacity: 0;
    }
  }

  .pswp{
    .pswp__button--close{
      margin-right: 10px;
    }

    .pswp__button--arrow--left, .pswp__button--arrow--right{
      color: black;
      font-size: 60px;
      background: none!important;
    }

    .pswp__button--arrow--right .fa{
      margin-right: 6px;
    }
    .pswp__button--arrow--left .fa{
      margin-left: -6px;
    }
  }

}

.customercare-specials {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  ul{
    padding: 0;
  }

  .customercare-specials__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .customercare-specials__title {
      font-size: 30px;
    }
  }

  .img-responsive{
    margin: auto;
  }

  .customercare-specials__tile{
    margin-top: 2em;
    strong{
      font-size: 24px;
    }
  }
}

/**
 *
 * .customercare END
 *
**/

/**
 *
 * .Home
 *
**/

.home-wrapper{

    background-image: url("../img/pasta.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    #pac-input {
        margin-bottom: 15px;
        text-align: center;
    }

    .home-form-container {
        text-align: center;
        display:table-cell;
        vertical-align:middle;

        .home-input {
            width: max-content;
            max-width: 720px;
            padding: 15px;
            background: white;
            margin: auto;
            border: 1px solid #ccc;
        }

        .mamasfud-address {
            border:0;
            width: 20em;
        }

        .button-home {
            background-color: @red-mamasfud;
            border:0;
            padding:10px 30px;
            color:white;
        }

    }

  .img-responsive{
    margin: auto;
  }

}
.home-wrapper__icons {
    margin:90px 0;
    h3 {
      color: @red-mamasfud;
      font-weight: 900;
    }
}


/**
 *
 * .Sellers
 *
**/

.sellers-product__wrapper {
    input, input:focus {
        width: 100%;
        border: 1px solid @dark-gray;
    }
}

.sellers-orders__products-table {
    width: 100%;
}

@media (min-width: @screen-md-min) {
    .home-wrapper{
        .product-contaier {
            .product-text {
                h4 {
                     top: 75px;
                }
                .btn-container{
                    top: 125px;
                }
            }
        }
    }
}

@media (min-width: @screen-lg-min) {
    .home-wrapper{
        .product-contaier {
            .product-text {
                h4 {
                     top: 100px;
                }
                .btn-container{
                    top: 150px;
                }
            }
        }
    }
}


/**
 *
 * .Home END
 *
**/



/**
 *
 * .returngoods
 *
**/

.returngoods-index {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  .returngoods-index__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .returngoods-index__title {
      font-size: 30px;
    }
  }

  .returngoods-index__title {
    font-size: 18px;
  }

  .returngoods-index__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .returngoods-index__item:nth-child(even){
    background-color: @light-gray;
  }
}

/**
 *
 * .returngoods END
 *
**/


/**
 *
 * .elements
 *
**/

.elements-pages__header {
  margin: 0 15px;
  padding: 20px 0;
  border-bottom: 1px solid @light-gray;

  .elements-pages__title {
    font-size: 30px;
  }
}

.elements-pages__products-wrapper{
  margin-top: 27px;

  .elements-pages__products-title{
    line-height: 32px;
    color: white;
    background-color: @dark-gray;
    font-size: 18px;
    font-weight: 500;
  }
}

.elements-pages__post {
    position: relative;
    margin-top: 15px;
    img {
        width: 100%;
        height: auto;
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
    }
}

.elements-product{

  a, a:hover, a:focus{
    text-decoration: none;
  }

  p{
    margin: 0;
  }

  .img-responsive{
    margin: auto;
  }

  .elements-product__actions-wrapper{
    position: relative;

    .elements-product__actions{
      background-color: fade(@dark-gray, 50%);
      color: white;
      opacity: 0;
      width: 100%;
      height: 100%;
      padding: 15px;
      position: absolute;
      -webkit-transition: 0.2s ease-out;
      -moz-transition: 0.2s ease-out;
      -o-transition: 0.2s ease-out;
      transition: 0.2s ease-out;

      .elements-product__wishlist-actions{
        left: 15px;
        right: 15px;
        top: 7px;
        position: absolute;


        .elements-product__wishlist-btn{
          color: white;
          font-size: 24px;
          float: right;
        }
      }

      .elements-product__cart-actions {
        bottom: 15px;
        left: 15px;
        right: 15px;
        position: absolute;

        .elements-product__size-label{
          font-size: 12px;
          margin-bottom: 10px;
        }

        .elements-product__sizes{

          ul{
            padding: 0;
          }

          li button{
            font-size: 10px;
            border: 1px solid white;
            color: white;
            float: left;
            width: 32px;
            height: 32px;
            margin: 0 8px  8px 0;
            text-align: center;
            line-height: 32px;
            overflow: hidden;
          }

          li button.active{
            color: black;
            background-color: white;
          }
        }

        .elements-product__cart-btn {
          color: white;
          line-height: 40px;
          width: 100%;
          margin-top: 5px;
          border: 1px solid white;
        }
      }
    }

  }

  .elements-product__actions-wrapper:hover {
    .elements-product__actions{
      opacity: 1;
    }
  }

  .elements-product__brand{
    padding: 10px 0;
    font-size: 14px;
    span{
      display: -webkit-box;
      height: 20px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .elements-product__divider{
    width: 120px;
    max-width: 100%;
    height: 1px;
    background-color: @dark-text;
    margin: auto;
  }

  .elements-product__text{
    line-height: 40px;
  }

  .elements-product__price--old{
    text-decoration: line-through;
  }

  .elements-product__price--discounted{
    color: @red-text;
  }
}

.elements-filters{
  padding: 0 15px;

  .elements-filters__section{
    ul{
      padding: 5px 15px;
    }
  }

  .panel-group {
    .panel{
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      .panel-heading{
        padding: 0;
      }

      .panel-title{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;

        .fa-chevron-right{
          font-size: 12px;
          margin-left: 5px;
          -webkit-transition: 0.2s ease-out;
          -moz-transition: 0.2s ease-out;
          -o-transition: 0.2s ease-out;
          transition: 0.2s ease-out;
        }
      }

      .panel-heading.open{
        .fa-chevron-right{
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      .panel, .panel-body {
        border: none!important;

        .panel-filter-lev2 {
            margin-left: 15px;
            font-size: 11px;
        }

        a.active{
          font-weight: bold;
          color: @dark-text;
        }
      }
    }
  }
}

.element-pages__pagination{
  ul{
    display: inline-block;
    padding: 0;
  }

  .element-pages__pagination-item{
    float: left;
    padding: 27px 10px;
  }
}


/**
 *
 * .elements END
 *
**/


/**
 *
 * .newsletterusers
 *
**/

.newsletterusers-subscribe {

  margin-bottom: 30px;

  .img-responsive {
    margin: auto;
    max-height: 180px;
  }

  .newsletterusers-subscribe__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .newsletterusers-subscribe__title {
      font-size: 30px;
    }
  }

  .newsletterusers-subscribe__title--p{
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
    color: @dark-text;
  }

  .newsletterusers-subscribe__title{
    font-size: 18px;
  }

  .newsletterusers-subscribe__wrapper {
    margin-top: 30px;

    .newsletterusers-subscribe__form{

      input[type=text],
      input[type=email],
      input[type=password], {
        border: 1px solid black;
        padding: 0 10px;
        width: 100%;
      }

      .newsletterusers-subscribe__terms-text{
        font-size: 11px;
      }
    }
  }

}
.newsletterusers-subscribe__form{
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}
.newsletterusers-subscribe__footer-input {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 50%;
    min-width: 320px;
    color: #000;
}

/**
 *
 * .newsletterusers END
 *
**/





/**
 *
 * .wishlists-index
 *
**/


/**
 *
 * .wishlists-index END
 *
**/

/**
 *
 * .products-index
 *
**/
.products-index{

  .img-responsive{
    margin: auto;
  }

  p{
    margin: 0;
  }

  .fa-chevron-left, .fa-chevron-right{
    color: black;
  }

  .products-index__header{
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .products-index__title{
      font-size: 30px;
    }
  }

  .products-index__detail{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;

    strong{
      color: @dark-text;
      font-weight: 800;
    }

    .products-index__brand{
      font-size: 28px;
      font-weight: 600;
    }

    .products-index__name{
      font-size: 28px;
      line-height: 23px;
    }

    .products-index__more{
      .fa-chevron-down{
        margin-left: 15px;
      }
    }

    .products-index__actions-wrapper{
      //padding: 30px;
      //border: 2px solid @light-gray;

      .products-index__price{
        font-size: 25px;
      }

      .products-index__price--discount{
        font-size: 25px;
        color: @red-text;
      }

      .products-index__price--original{
        text-decoration: line-through;
      }

      .products-index__size-wrapper{
        margin-top:15px;

        strong{
          line-height: 24px;
        }
      }

      .products-index__cart-btn{
        //margin-top: 15px;
        height: 40px!important;
      }

      .products-index__wishlist-btn{
        //margin-top: 5px;
        height: 40px!important;
      }

      .products-index__social-wrapper{
        font-size: 18px;
        margin-top: 10px;
        line-height: 14px;

        ul{
          padding: 0;
          display: inline-block;

          li{
            float: left;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .swiper-container{
    font-size: 34px;

    .swiper-button-disabled{
      opacity: 0;
    }
  }

  .products-index__relateds{
    margin: 30px 0;
  }

  .products-index__sizes-modal{
    td:not(:first-child){
      text-align: center;
    }
  }

  .pswp{
    .pswp__button--close{
      margin-right: 10px;
    }

    .pswp__button--arrow--left, .pswp__button--arrow--right{
      color: black;
      font-size: 60px;
      background: none!important;
    }

    .pswp__button--arrow--right .fa{
      margin-right: 6px;
    }
    .pswp__button--arrow--left .fa{
      margin-left: -6px;
    }
  }
}


/**
 *
 * .products-index END
 *
**/

/**
 *
 * .wishlists-index
 *
**/

.wishlists-index {

  margin-bottom: 30px;

  label {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  .wishlists-index__header {
      margin: 0 15px;
      padding: 20px 0;
      border-bottom: 1px solid @light-gray;

    .wishlists-index__title {
      font-size: 30px;
    }
  }

  .wishlists-index__menu-wrapper {
    ul {
      li {
        margin-bottom: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

}

/**
 *
 * .wishlists-index END
 *
**/


.shop-search {
    margin-top:15px;
    margin-bottom:15px;
    h2, h3 {
        color:@red-mamasfud;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
    }
    ul {
        padding:0;
    }
    .shop-search__address {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 15px;
        span {
            color:@red-mamasfud;
        }
    }
}
.shop-restaurant {
    margin-top:15px;
    margin-bottom:15px;
    color: #acacac;

    h1, h3 {
        font-size: 18px;
        color: @red-mamasfud;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .shop-restaurant__left {
        table {
            width: 100%;
        }
    }

    .shop-restaurant__category {
        color: #acacac;
        line-height: 30px;
        padding: 15px 0;
        border-bottom:1px solid #acacac;
        strong {
            color: @red-mamasfud;
            margin: 0 30px;
        }
    }

    .shop-restaurant__product {
        line-height: 30px;
        border-bottom:1px solid #acacac;
        .fa {
            font-size: 16px;
            color: @red-mamasfud;
        }
    }

    .shop-restaurant__right {
        background-color: #f7f7f7;
    }

    hr {
        border-top-color: #acacac;
        margin: 5px 0;
    }

    select {
        width: 100%;
        border: 1px solid #acacac;
    }

    .shop-restaurant__cart-actions {
        padding-right: 0;
    }

    .shop-restaurant__cart-price {
        padding-left: 0;
    }

    .shop-restaurant__call-to-action {
        display: block;
        width: 100%;
        padding: 5px;
        background-color: @red-mamasfud;
        color: #fff;
        text-transform: uppercase;
    }

}
.text-red {
    color: @red-mamasfud;
}

/**
 *
 * footer
 *
**/

.layout__footer{
  /*min-height: 200px;*/
  background-color: @red-mamasfud;
  color: white;
  clear: both;
  /*position: absolute;*/
  width: 100%;
  bottom: 0;



  .layout__footer-logo-wrapper{

    a, a:hover, a:visited{
      color: white;
      text-decoration: none;
    }

    height: 100%;

    .layout__footer-logo-name{
      font-size: 32px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .layout__footer-logo-name--thin{
      font-size: 32px;
      font-weight: 100;
    }
  }

  .layout__footer-menu{

    margin-top: 18px;

    a{
      color: white;
    }

    h4{
      margin: 0;
    }

    ul{
      padding-left: 0;
      margin-top: 1.5em;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
      margin-bottom: 15px;

      span{
        color: white;
        text-transform: uppercase;
      }
    }

    ul.no-border{
      border-bottom: none;
    }
  }

  .layout__footer-bar{
    background-color: white;
    color: black;
    min-height: 36px;
    font-weight: 500;
    p{
      margin: 0;
    }
  }
}

/**
 *
 * footer end
 *
**/

#filtersModal{
  .modal-dialog{
    width: 100%;
    margin: 0;
  }

  .modal-content{
    background-color: rgba(255,255,255, 0.9);

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .panel{
    background-color: transparent;
  }
}


/**
 *
 * MEDIA QUERYES
 *
**/


/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
  body {

    .home-wrapper{

        #pac-input {
            margin-bottom: auto;
        }

      .home__newsletter {
        margin-top: 0;
        padding-left: 15px;
        padding-right: 0;
      }


      .home__sales {
        padding-right: 15px;
        padding-left: 0;

        a{
          font-size: 48px;
        }

      }
    }

    label.error {
      margin-top: -5px;
    }

    /*.mm-slideout{
      padding-bottom: 248px;
    }*/

    .layout__header {
      .layout__header-menu {
        /*
        height: 87px;
        */
      }
      .layout__header-menu{

        .layout__header-logo-wrapper{
          width: 100%;
          .layout__header-logo-name{
            font-size: 48px;
          }

          .layout__header-logo-name--thin{
            font-size: 48px;
          }
        }
      }
      .layout__header-bar-shopmenu{
        a, a:link, a:hover, a:focus, a:active{
          line-height: 50px;
        }
      }

    }

    .layout__header-bar-logo {
        margin-top: 0px;
    }

    .layout__header-bar-menu{

      color: @dark-text;
      li{
        float: left;
        line-height: 40px;
        span{
          padding-left: 10px;
          padding-right: 10px;
          border-right: 1px solid @mid-gray;
          font-weight: 500;
        }
      }

      li:first-child{
        span{
          padding-left: 0;
        }
      }

      li:last-child{
        span{
          border-right: none;
        }
      }
    }

    .layout__header-bar-shopmenu{
        margin: 0;
    }

    .layout__search {
      font-size: 2em;
      min-width: 330px;
    }

  }

  .layout__footer{
    /*position: absolute;*/

    .layout__footer-menu{

      ul{
        border-bottom: none;
        margin-bottom: inherit;
      }
    }

    .layout__footer-bar{
      line-height: 36px;
    }
  }

  .elements-cart {
    .elements-cart__payments {
      ul {
        li {
          width: 50px;
        }
      }
    }
  }

  .accounts-passwordforgotten, .accounts-passwordreset {


    .accounts-passwordforgotten__header, .accounts-passwordreset__header {
      .accounts-passwordforgotten__title, .accounts-passwordreset__title {
        font-size: 30px;
      }
    }
  }

  .elements-addressesblock__address-container:nth-child(odd){
    padding-right: 0.5em;
  }
  .elements-addressesblock__address-container:nth-child(even){
    padding-left: 0.5em;
  }

  .addresses-index__item:nth-child(odd){
    padding-right: 0.5em;
  }
  .addresses-index__item:nth-child(even){
    padding-left: 0.5em;
  }

  .addresses-index__address-container:nth-child(odd){
    padding-right: 0.5em;
  }
  .addresses-index__address-container:nth-child(even){
    padding-left: 0.5em;
  }
  .accounts-dashboard__address:nth-child(odd){
    padding-right: 0.5em;
  }
  .accounts-dashboard__address:nth-child(even){
    padding-left: 0.5em;
  }

  .newsletterusers-subscribe__form{
      border-bottom: 0;
      padding-bottom: 0;
  }

  .products-index__wishlist-container{
    padding-left: 15px!important;
  }



}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {

  body {

    .home-wrapper{
      .title-wrapper {
        strong {
          font-size: 36px;
        }
      }

      .home__text {
        font-size: 32px;
      }
    }

    /*.mm-slideout{
      padding-bottom: 221px;
    }*/

    .layout__header {

      .layout__header-menu {
        /*
        height: 87px;
        */

        .layout__header-logo-wrapper {
          padding-left: 15px;
          width: auto;

          .layout__header-logo-name {
            line-height: 87px;
          }

          .layout__header-logo-name--thin {
          }
        }
      }
    }

    .layout__footer-logo-wrapper {
      padding-left: 15px;
    }

    .accounts-login {
      .accounts-login__wrapper {
        .accounts-login__form-wrapper {
          border-right: 1px solid @light-gray;
        }

        .accounts-login__register-wrapper {
          margin-top: 0;
        }
      }
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {

  body {

    /*.mm-slideout{
      padding-bottom: 203px;
    }*/


  }
}

/*
 *
 *  End of template classing
 *
**/

.mm-listview li span {
  font-size: 16px;
}
.mm-listview li span small {
  font-size: 12px;
}
.mm-listview li span img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin: -5px 10px -5px -5px;
  float: left;
}


.mm-listview > li > a,
.mm-listview > li > span {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
}
